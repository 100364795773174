
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import UserAccess from "@/model/User/UserAccess";
import Multiselect from "vue-multiselect";
import TableHeader from "@/components/tableHeader.vue";
import IbotechService from "@/services/ibotechService";
import { WEP_MOCK_URL } from "@/shared/keycloak";

@Component({
  name: "SignMeasurement",
  components: { TableHeader, Multiselect },
  computed: {
    ...mapGetters([
      "getOrdersBatchData",
      "getTotalOrdersBatchList",
      "getUserAccess",
      "getCurrentUser",
      "isIbotechSignMeasurementUser",
      "isIbotechSignMeasurementAdmin"
    ])
  },
  methods: {
    ...mapActions(["getAllOrdersBatch", "getAllOrdersSearch"])
  }
})
export default class SignMeasurement extends Vue {
  WEP_MOCK_URL = WEP_MOCK_URL;

  private getOrdersBatchData!: Array<any>;
  private getTotalOrdersBatchList!: number;
  private getAllOrdersBatch!: Function;

  readonly getUserAccess!: UserAccess;
  readonly getCurrentUser!: any;

  loading: boolean = false;
  currentPage: number = 1;
  perPage: number = 30;
  searchInTableFor: string = "";
  filter: string = "";

  fields: Array<string | any> = [
    {
      key: "selected",
      sortable: false,
      label: this.translateTableLabel("selected")
    },
    {
      key: "id",
      sortable: false,
      label: "ID"
    },
    { key: "number", sortable: true, label: this.translateSignLabel("number") },
    { key: "project", sortable: true, label: this.translateSignLabel("projectNumber") },
    { key: "material", label: "Material" },
    { key: "batchNumber", label: this.translateSignLabel("batchNumber") },
    { key: "actions", label: this.translateTableLabel("actions") }
  ];

  materialOptions: Array<{ label: string; value: string }> = [
    { label: "3M Yellow", value: "M_3M_YELLOW" },
    { label: "3M Cyan", value: "M_3M_CYAN" },
    { label: "3M Black", value: "M_3M_BLACK" },
    { label: "3M Magenta", value: "M_3M_MAGENTA" },
    { label: "3M Red", value: "M_3M_RED" },
    { label: "3M Blue", value: "M_3M_BLUE" },

    { label: "Orafol Black", value: "ORAFOL_BLACK" },
    { label: "Orafol Green", value: "ORAFOL_GREEN" },
    { label: "Orafol Yellow", value: "ORAFOL_YELLOW" },
    { label: "Orafol Blue", value: "ORAFOL_BLUE" },
    { label: "Orafol Brown", value: "ORAFOL_BROWN" },
    { label: "Orafol Red", value: "ORAFOL_RED" },

    { label: "3M RA2", value: "M_3M_RA2" },
    { label: "3M RA3", value: "M_3M_RA3" },
    { label: "3M Laminat", value: "M_3M_LAMINAT" },

    { label: "Orafol RA2", value: "ORAFOL_RA2" },
    { label: "Orafol RA3", value: "ORAFOL_RA3" },
    { label: "Orafol Laminat", value: "ORAFOL_LAMINAT" },
    { label: "Orafol Laminat Anti", value: "ORAFOL_LAMINAT_ANTI" },

    { label: "Dibond 2mm", value: "DIBOND_2MM" },
    { label: "Dibond 3mm", value: "DIBOND_3MM" },
    { label: "Dibond 4mm", value: "DIBOND_4MM" },
    { label: "Alu 2mm", value: "ALU_2MM" },
    { label: "Alu 3mm", value: "ALU_3MM" },

    { label: "Alform 1 Border Profile", value: "ALFORM_1_BORDER_PROFILE" },

    { label: "Alform 2 Border Profile", value: "ALFORM_2_BORDER_PROFILE" },
    { label: "Alform 2 Border Corner", value: "ALFORM_2_BORDER_CORNER" },
    { label: "Alform 2 Truses", value: "ALFORM_2_TRUSES" },

    { label: "Alform 3 Border Profile", value: "ALFORM_3_BORDER_PROFILE" },
    { label: "Alform 3 Border Corner", value: "ALFORM_3_BORDER_CORNER" },
    { label: "Alform 3 Truses", value: "ALFORM_3_TRUSES" }
  ];

  formBatch: any = {
    id: "",
    project: "",
    number: "",
    material: {
      label: "",
      value: ""
    },
    batchNumber: ""
  };

  created() {
    // Redirect to 404
    if (!this.getUserAccess.AccessIbotech.VIEW.signMeasurement) {
      this.$router.push({ name: "NotFound" });
    }

    this.fetchOrderBatchData();
  }

  // --------------------------------------------------
  // --------------------------------------------------
  // --------------- Main Function --------------------
  // --------------------------------------------------
  // --------------------------------------------------

  async fetchOrderBatchData() {
    this.loading = true;

    this.getAllOrdersBatch().then(() => {
      this.loading = false;
    });
  }

  get getData() {
    return this.getOrdersBatchData;
  }

  onRowSelected(batch: Array<any>) {
    this.$emit("rowSelected", batch);
  }

  translateTableLabel(label: string) {
    return this.$t(`ibotech_location.locationTableHeadlines.${label}`);
  }

  translateSignLabel(label: any) {
    return this.$t(`ibotech_sign_measurement.form.${label}`);
  }

  filterActionHandler(filterAction: string) {
    this.filter = filterAction;
  }

  // ------------------------------------------------------------
  // ------------------------------------------------------------
  // --------------------- Chargennummer ------------------------
  // ------------------------------------------------------------
  // ------------------------------------------------------------

  openBatchModal() {
    this.$bvModal.show("newBatch");
  }

  async submitBatch(action: any) {
    if (this.isFormValid) {
      this.formBatch.material = this.formBatch.material.value;
      let res = await IbotechService.addBatch(this.formBatch);

      if (res.status == 202) {
        if (action === "more_entries") {
          // Projectnumber and Ordernunber must be filled in
          this.formBatch.batchNumber = "";
          this.formBatch.material = {
            label: "",
            value: ""
          };
        } else {
          this.closeModal();
        }

        this.success();
        this.fetchOrderBatchData();
      } else {
        this.error();
      }
    } else {
      this.error();
    }
  }

  async confirmDeleteBatch(id: any) {
    this.$bvModal
      .msgBoxConfirm((this as any).$t("ibotech_sign_measurement.deleteEntryDescription"), {
        title: (this as any).$t("ibotech_sign_measurement.deleteEntry"),
        size: "md",
        buttonSize: "md",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0 bg-light",
        footerClass: "p-2 border-top-0",
        centered: true,
        okTitle: (this as any).$t("utils.alertBox.ok"),
        cancelVariant: "light",
        cancelTitle: (this as any).$t("utils.alertBox.cancel")
      })
      .then((value: boolean) => {
        if (value) {
          this.deleteBatch(id);
        }
      });
  }

  async deleteBatch(id: any) {
    let res = await IbotechService.deleteBatch(id);

    if (res.status == 200) {
      this.success();
      this.fetchOrderBatchData();
    } else {
      this.error();
    }
  }

  closeModal() {
    this.$bvModal.hide("newBatch");
    this.resetForm();
  }

  resetForm() {
    this.formBatch = {
      id: "",
      project: "",
      number: "",
      material: {
        label: "",
        value: ""
      },
      batchNumber: ""
    };
  }

  get isFormValid() {
    return Object.entries(this.formBatch).every(([label, data]) => {
      //
      var copyData: any = data;

      if (label === "id") return true; // ID darf leer sein
      if (label === "project") return true; // ID darf leer sein

      if (typeof copyData === "object" && copyData !== null) {
        return copyData.value !== "" && copyData.value !== null && copyData.value !== undefined;
      }

      return copyData !== "" && copyData !== null && copyData !== undefined;
    });
  }

  success() {
    // Success Message
    this.$bvToast.toast(this.$t("ibotech_vehicle.newVehicleForm.successMessageText").toString(), {
      title: this.$t("ibotech_vehicle.newVehicleForm.successMessageTitle").toString(),
      autoHideDelay: 1500,
      variant: "success"
    });
  }

  error() {
    this.$bvToast.toast(this.$t("ibotech_vehicle.newVehicleForm.errorMessageText").toString(), {
      title: this.$t("ibotech_vehicle.newVehicleForm.errorMessageTitle").toString(),
      autoHideDelay: 1500,
      variant: "danger"
    });
  }

  get deleteEntryRight() {
    return this.getUserAccess.AccessIbotech.VIEW.signMeasurementBatchDelete;
  }
}
